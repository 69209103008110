import { Injectable } from '@angular/core';
import { StoreDetailService } from './resolver/store-detail.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from './storage/local-storage.service';

const COOKIE_STORE_KEY: string = 'ck-config';

interface CookieConfigs {
  isConfirmed: boolean;
  analytics: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  isConfirmed: boolean = false;

  constructor(
    private storeDetailService: StoreDetailService,
    private localStorage: LocalStorageService
  ) {
    if(this.cookieConfigs) {
      this.isConfirmed = this.cookieConfigs.isConfirmed;
      this.cookieConfigs.analytics ? this.enableGoogleAnalytics() : this.disableGoogleAnalytics()
    }
  }
  
  enableGoogleAnalytics(): void {
    this.toggleAnalytics(false);
  }

  disableGoogleAnalytics(): void {
    this.toggleAnalytics(true);
  }

  saveConfig(config: CookieConfigs, reload?: boolean) {
    this.cookieConfigs = config;
    this.deleteAllCookies();
    if(reload) window.location.reload();
  }

  private toggleAnalytics(disable: boolean) {
    const analyticsMeasurementID = this.storeDetailService.permissionInit.firebase_config.measurementId;
    if(analyticsMeasurementID) {
      window[`ga-disable-${analyticsMeasurementID}`] = disable;
    }
  }

  private get cookieConfigs(): CookieConfigs {
    return this.localStorage.getItem(COOKIE_STORE_KEY)
  }

  private set cookieConfigs(configs: CookieConfigs) {
    this.localStorage.setItem(COOKIE_STORE_KEY, configs)
    this.isConfirmed = configs.isConfirmed;
  }

  private deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }
  }
}
