import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { filter, map, pairwise, throttleTime } from 'rxjs/operators';
import { PaginationService } from '../../services/pagination/pagination.service';

@Component({
  selector: 'infinite-scroll-provider',
  templateUrl: './infinite-scroll-provider.component.html',
  styleUrls: ['./infinite-scroll-provider.component.scss']
})
export class InfiniteScrollProviderComponent implements AfterViewInit {

  @ViewChild('providerElement') providerElement: CdkVirtualScrollViewport;

  constructor(
    private paginationService: PaginationService
  ) { }

  ngAfterViewInit(): void {
    this.providerElement.elementScrolled().pipe(
      map(() => this.providerElement.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1, y2]) => (y2 < y1 && y2 < 140)),
      throttleTime(200)
    ).subscribe(() => {
      this.paginationService.runNextPageMethod();
    })
  }

}
