import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface paginatedResponse {
  count: number;
  next: string;
  previous: string;
  results: any[];
}

type NextPageMethod = () => void;
const _default_value = () => undefined;

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  private nextPageMethod: () => void = _default_value;

  constructor(
    private http: HttpClient
  ) { }

  setNextPageMethod(method: NextPageMethod) {
    this.nextPageMethod = method;
  }

  clearNextPageMethod() {
    this.nextPageMethod = _default_value;
  }

  runNextPageMethod() {
    if(!this.nextPageMethod) return;
    return this.nextPageMethod();
  }

  fetchNextData(next: string): Observable<paginatedResponse> {
    return this.http.get<any>(next);
  }
}
