import {  Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class TempStorageService {

  private tempStorage = {};

  constructor() {}

  getItem(item: string) {
    return this.tempStorage[item];
  }

  setItem(item: string, data) {
    this.tempStorage[item] = data;
  }

  deleteItem(item) {
    delete this.tempStorage[item];
  }

  get isLoggedIn() {
    return (
      !!this.getItem('accessToken') &&
      !!this.getItem('refreshToken')
    );
  }

  logOutUser() {
    this.deleteItem('accessToken')
    this.deleteItem('refreshToken')    
  }
}
