import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CountryDetail } from 'src/app/modules/shared/models/profile.mode';
import { PanelService } from 'src/app/modules/shared/services/api/panel.service';
import { RecentlySearchedService } from 'src/app/modules/shared/services/recently-searched.service';
import { LocalStorageService } from 'src/app/modules/shared/services/storage/local-storage.service';

@Component({
  selector: 'search-suggestion-view',
  templateUrl: './search-suggestion-view.component.html',
  styleUrls: ['./search-suggestion-view.component.scss']
})
export class SearchSuggestionViewComponent implements OnInit {

  recentSearches: string[];
  trendingSearches;
  isServer: boolean;

  constructor(
    private localStorage: LocalStorageService,
    private panelService: PanelService,
    private router: Router,
    private dialog: MatDialog,
    private recentlySearchedService: RecentlySearchedService,
    @Inject(PLATFORM_ID) platFormId: Object
  ) {
    this.isServer = isPlatformServer(platFormId);
  }

  ngOnInit(): void {
    this.getTrendingQueries();
    this.recentSearches = this.recentlySearchedService.getKeywords();
  }

  removeRecentlySearchedItem(item: string): void {
    const index = this.recentSearches.indexOf(item);
    this.recentSearches = this.recentlySearchedService.removeKeyword(index);
  }

  goToQuery(query: string) {
    this.router.navigate(['/search', query, 'all']).then(status => {
      this.dialog.closeAll();
    });
  }

  private getTrendingQueries() {
    let storeRegion: CountryDetail = !this.isServer
      ? this.localStorage.getItem('store-region')
      : '';
    this.panelService
      .getTrendingQueries(storeRegion?.code)
      .subscribe((result) => {
        this.trendingSearches = result;
      });
  }

}
