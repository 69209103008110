
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PanelService } from '../api/panel.service';
import { LocalStorageService } from '../storage/local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class WalletBalanceService {
  private walletBalance = new BehaviorSubject<any>('');

  public constructor(
    private localStorageService: LocalStorageService,
    private panelService: PanelService,
  ) { }

  public resolveInfo(): Promise<any> {

    if (this.localStorageService.isLoggedIn) {
      return new Promise((resolve, reject) => {
        this.panelService.WalletBalance().subscribe(
          data => {
            this.walletBalance.next(data.remaining)
            resolve("");
          }, error => {
            reject();
          }
        )
      })
    }

  }

  get permissionInitInfo() {
    return this.walletBalance.asObservable();
  }

  set permissionInitInfo(info) {
    this.walletBalance.next(info)
  }

  public updateInfo() {
    return this.resolveInfo();
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.resolveInfo();
  }
}
