export const BinanceBridgeKey = '__NEZHA_BRIDGE__';

export interface BinancePaymentPayload {
  certSn: any;
  merchantId: string;
  timeStamp: any;
  nonceStr: any;
  package: string;
  paySign: any;
}

export enum BinanceAction {
  GET_USER_OPEN_ID = 'getUserOpenid',
  REQUEST_PAYMENT = 'requestPayment',
}
