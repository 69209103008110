import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Product } from '../../models/products.model';
import { FormGroup } from '@angular/forms';

interface PaymentData {
  step: string;
  product: Product;
  payablePrice: {
      notValid: string;
      price: number;
      currency: string;
  };
  deliverFormVariable: {
      cardType: string;
      reciveType: string;
      placeHolder: string;
  };
  createOrderForm: FormGroup;
  order: any;
  subscriptions: Subscription[];
  exchangeRate: number;
  activationFee: number
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor() {}

  public showLoading = new BehaviorSubject<boolean>(false);
  public isLoggedIn = new BehaviorSubject<boolean>(false);
  public userLanguage = new BehaviorSubject<string>('en');
  // public profileStep = new BehaviorSubject<number>(1);
  public walletBalance = new BehaviorSubject<boolean>(false);
  public isSearching = new Subject<boolean>();
  public isWalletReloaded = new BehaviorSubject<boolean>(false);
  public paymentData = new BehaviorSubject<PaymentData>(undefined);
  public count: number = 0;

  getPaymentData(): Observable<PaymentData> {
    return this.paymentData.asObservable();
  }

  setPaymentData(data: PaymentData) {
    this.paymentData.next(data);
  }

  getLanguageStatus(): Observable<string> {
    return this.userLanguage.asObservable();
  }

  getLoadingStatus(): Observable<boolean> {
    return this.showLoading.asObservable();
  }

  getLoginStatus(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  setLanguageStatus(status: string) {
    this.userLanguage.next(status);
  }

  setLoginStatus(status: boolean) {
    this.isLoggedIn.next(status);
  }

  startLoading() {
    this.count += 1;
    this.checkLoadingStatus();
  }

  endLoading() {
    this.count -= 1;
    this.checkLoadingStatus();
  }

  checkLoadingStatus() {
    if (this.count < 0) {
      this.count = 0;
    }

    if (this.count < 1) {
      this.showLoading.next(false);
    } else {
      this.showLoading.next(true);
    }
  }

  // getProfileStep(): Observable<number> {
  //   return this.profileStep.asObservable();
  // }
  // setProfileStep(step: number) {
  //   this.profileStep.next(step);
  // }

  getSearchStatus(): Observable<boolean> {
    return this.isSearching.asObservable();
  }

  setSearchStatus(status: boolean) {
    this.isSearching.next(status);
  }

  getIsWalletReloaded(): Observable<boolean> {
    return this.isSearching.asObservable();
  }

  setIsWalletReloaded(status: boolean) {
    this.isSearching.next(status);
  }
}
