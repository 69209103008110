import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { StoreDetail } from '../../models/store-details.model';
import { StoreDetailService } from '../../services/resolver/store-detail.service';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

const FOOTER_STATIC_PAGES = [ 'return-policy', 'cookie-policy', 'privacy-policy' ];
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  storeDetail: StoreDetail;
  copyrightTitle: string;
  copyrightUrl: string;
  emailField = new FormControl('', [Validators.email, Validators.required]);
  staticPages;
  hideNavigation: boolean = false;


  constructor(
    private storeDetailResolver: StoreDetailService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) { 
    this.hideNavigation = this.isNoneNavigationRoute;
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.hideNavigation = this.isNoneNavigationRoute;
      }
    })
  }

  ngOnInit(): void {
    this.getStoreDetail();
  }

  onSubmitEmail() {
  }

  getStoreDetail() {
    this.storeDetail = this.storeDetailResolver?.permissionInit;
    this.staticPages = this.storeDetail.static_pages.filter(obj => 
      FOOTER_STATIC_PAGES.some(str => obj.slug === str)
    );    
    this.copyrightTitle = this.storeDetailResolver?.permissionInit?.copyright_title;
    this.copyrightUrl = this.storeDetailResolver?.permissionInit?.copyright_url;
  }

  scrollToTop() {
    this.document.getElementById('mainPage').scrollIntoView({
      behavior: 'smooth',
    })
  }

  private get isNoneNavigationRoute() {
    return this.router.url.includes('/authenticate');
  }
}
