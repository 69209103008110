import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { DeviceDetectorService } from '../../services/device-detector/device-detector.service';
import {
  DialogPosition,
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { PanelService } from '../../services/api/panel.service';
import { StoreRegionService } from '../../services/store-region.service';

@Component({
  selector: 'shared-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent implements OnInit {
  searchState = {
    IDLE: 'idle',
    SEARCHING: 'searching',
    NAVIGATING: 'navigating',
  };

  @Input() opened: boolean;
  @Output() onFocus = new EventEmitter<any>();
  @ViewChild('searchDialog') searchDialog: any;
  @ViewChild('searchFielfContainer') searchFielfContainer: ElementRef;
  dialogRef: MatDialogRef<any>;
  searchedQuery: string;
  searchField = new FormControl();
  state = this.searchState.IDLE;
  prevRouteTarget: string;
  isDesktop: boolean;
  searchResult: any[];

  constructor(
    private router: Router,
    private deviceDetectorService: DeviceDetectorService,
    private dialog: MatDialog,
    private panelService: PanelService,
    private regionService: StoreRegionService,
    private googleTagManagerService: GoogleTagManagerService
  ) {
    this.deviceDetectorService.$breakpoint().subscribe((sizes) => {
      this.isDesktop = sizes.matches;
      this.closeDialog();
      !this.isDesktop &&
        this.searchField.setValue(decodeURIComponent(this.searchParam), {
          emitEvent: false,
        });
    });
  }

  ngOnInit(): void {
    this.initSearchListener();
  }

  goToDetails() {
    this.router
      .navigate(['/search', this.searchField.value, 'all'])
      .then((status) => {
        this.closeDialog();
      });
  }

  private get searchParam() {
    let param = '';
    const urlTree = this.router.url.split('/');
    if (urlTree[1] && urlTree[1] === 'search' && urlTree[2]) {
      param = urlTree[2];
    }
    return param;
  }

  private initSearchListener() {
    this.searchField.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.onSearchSubmit();
      });
  }

  private setSearchParam(query: string) {
    this.state = this.searchState.SEARCHING;
    this.router.navigateByUrl(`/search/${query}`).then((status) => {
      this.sendSearchEvent(query);
      this.state = this.searchState.IDLE;
      this.focusSearchInput();
    });
  }

  private focusSearchInput() {
    document.getElementById('search-field-input').focus();
  }

  private sendSearchEvent(term: string) {
    this.googleTagManagerService.sendAnalytics('search', { term });
  }

  onFocusSearchField() {
    if (this.isDesktop) {
      if (!this.opened) {
        this.onFocus.emit(true);
        this.openDialog();
      }
      return;
    }

    const searchRoute = '/search';
    if (this.router.url.includes(searchRoute)) return;
    this.router.navigateByUrl(searchRoute).then((status) => {
      this.focusSearchInput();
    });
  }

  backToPrevRoute() {
    if (this.isDesktop) {
      this.closeDialog();
      return;
    }

    this.clearSearch(false);
    this.router.navigateByUrl('/');
  }

  onSearchSubmit() {
    const keyword = this.searchField.value.trim();
    if (keyword.length >= 2) {
      this.searchedQuery = keyword;
      if (this.isDesktop) {
        this.performSearch(this.searchedQuery);
      } else {
        this.setSearchParam(this.searchedQuery);
      }
    } else if (keyword.length === 0) {
      !this.isDesktop && this.router.navigateByUrl('/search');
    }
  }

  private performSearch(query: string) {
    this.panelService
      .searchProductByName(query, this.regionService.selectedRegion.id)
      .subscribe((res) => {
        this.searchResult = res.results.map((item) => ({
          ...item,
          url: this.getUrl(item.id, item.name),
        }));
      });
  }

  private getUrl(id: number, name: string) {
    let title = name.replace(/[^a-zA-Z0-9]+/g, '-');
    return `/product/${id}/${title}`;
  }

  clearSearch(emitEvent: boolean = true) {
    this.searchField.setValue('', { emitEvent });
  }

  private get dialogPositions(): DialogPosition {
    const { offsetTop, offsetHeight, offsetLeft } = this.searchFielfContainer
      .nativeElement as HTMLElement;
    const suffix = 'px';
    const marginTop = 10;

    return {
      top: offsetTop + offsetHeight + marginTop + suffix,
      left: offsetLeft + suffix,
    };
  }

  openDialog() {
    this.closeDialog();
    setTimeout(() => {
      this.dialogRef = this.dialog.open(this.searchDialog, {
        autoFocus: false,
        restoreFocus: false,
        position: this.dialogPositions,
        width: this.searchFielfContainer.nativeElement.offsetWidth,
        panelClass: ['desktop-search-dialog'],
        hasBackdrop: false,
      });
      this.dialogRef.afterOpened().subscribe((data) => {
        this.focusSearchInput();
      });
      this.dialogRef.afterClosed().subscribe((data) => {
        this.onFocus.emit(false);
        this.clearSearch(false);
        this.searchResult = undefined;
      });
    }, 300);
  }

  private closeDialog() {
    if (this.dialogRef && this.dialogRef.getState() !== MatDialogState.CLOSED) {
      this.dialogRef.close();
    }
  }

  handleClickOutside(target: HTMLElement) {
    if (!this.isDesktop || !this.opened || !this.dialogRef) return;

    const dialogElement =
      this.dialogRef['_containerInstance']['_elementRef'].nativeElement;
    const clickedInsideDialog = dialogElement.contains(target);
    if (!clickedInsideDialog) {
      this.closeDialog();
    }
  }
}
