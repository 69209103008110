import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { PanelService } from '../api/panel.service';

@Injectable({
  providedIn: 'root'
})
export class StoreGroupResolverResolver implements Resolve<boolean> {
  storeGroups:any
  constructor(
    private panelService:PanelService
  ){}

  private resolveInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.panelService.getStoreGroups().subscribe(
        (data) => {
          this.storeGroups = data;
          resolve('');
        },
        (error) => {
          reject();
        }
      );
    });
  }

  get permissionInit() {
    return this.storeGroups;
  }

  public updateInfo() {
    this.resolveInfo();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.resolveInfo();
  }
}
