<!-- Mobile Navigation -->
<div class="header-mobile" *ngIf="!isDesktop">
  <div class="header-content" [ngClass]="{'no-border': isSearchOpened}" *ngIf="!hideNavigation">
    <div class="mobile-logo-container" [ngClass]="{'show': !isSearchOpened}">
      <ng-container *ngTemplateOutlet="Logo"></ng-container>
    </div>
    <shared-search-field [ngClass]="{'expanded': isSearchOpened}" [opened]="isSearchOpened"></shared-search-field>
  </div>
</div>
<!-- ---------- -->

<!-- Desktop Navigation -->
<div class="header-desktop" *ngIf="isDesktop">
  <div class="header-content" *ngIf="!hideNavigation">
    <div class="column-item">
      <div class="desktop-logo-container" [ngClass]="{'add-space': isSearchOpened}">
        <ng-container *ngTemplateOutlet="Logo"></ng-container>
      </div>
      <div class="desktop-navigation" [ngClass]="{'show': !isSearchOpened}">
        <ng-container *ngTemplateOutlet="Navigation"></ng-container>
      </div>
    </div>
    <div class="column-item" [ngClass]="{'expand': isSearchOpened}">
      <shared-search-field [ngClass]="{'expanded': isSearchOpened}" [opened]="isSearchOpened" (onFocus)="searchOnFocus($event)"></shared-search-field>
      <div class="divider"></div>
        <ng-container *ngTemplateOutlet="preferenceButton"></ng-container>
        
      <div class="divider"></div>
      <a
        *ngIf="!isLoggedIn"
        mat-button
        [routerLink]="[ '/authenticate/login' ]"
        color="primary"
        class="custom-primary"
      >Login</a>
      <a
        *ngIf="isLoggedIn"
        mat-button
        [routerLink]="[ '/profile' ]"
        class="transparent-btn"
      >
        <img src="/assets/images/icons/navigation-user.svg" alt="">
      </a>
    </div>
  </div>
</div>
<!-- ---------- -->

<ng-template #Logo>
  <a class="logo-link" [routerLink]="[ '/' ]">
    <div class="logo-container">
      <img class="logo-mobile" [src]="'/assets/images/kingift-logo.svg'" alt="kingift logo" />
    </div>
  </a>
</ng-template>

<ng-template #preferenceButton>
  <button mat-button class="preference-btn" (click)="openPreference()">
    <div class="btn-context">
      <div class="btn-avatar">
        <img [src]="selectedRegion?.image" alt="" />
      </div>
      <div class="btn-label">EN</div>
    </div>
  </button>
</ng-template>

<ng-template #Navigation>
  <div class="nav-container">
    <a class="nav-link" [routerLink]="[ '/' ]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a>
    <button mat-button class="nav-action" [ngClass]="{'active': categoryDialogIsOpen}" (click)="openCategoeis()">
      <div class="button-context">
        <div class="button-title">Categories</div>
        <div class="button-icon">
          <img src="/assets/images/icons/arrow-right.svg" alt="" />
        </div>
      </div>
    </button>
    <a class="nav-link" [routerLink]="['/pages/contact-us']" routerLinkActive="active">Contact Us</a>
  </div>
</ng-template>

<ng-template #categoriesDialog>
  <div class="dialog-container" *ngIf="categories">
    <a class="category-item" *ngFor="let item of categories" [routerLink]="[item.url]" (click)="closeDialog()">
      <div class="context">
        <div class="avatar-co">
          <img
            class="avatar"
            [src]="item.avatar"
            [alt]="item.name"
          />
        </div>
        <p class="title">{{item.name}}</p>
      </div>
      <img class="action-icon" src="/assets/images/icons/Arrow-right-icon.svg" alt="">
    </a>
  </div>
</ng-template>

<ng-template #prefrencesDialog>
  

</ng-template>