import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StateService } from './storage/state.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class DynamicScript {
  private isBrowser: boolean;

  constructor(
    private stateService: StateService,
    @Inject(PLATFORM_ID) platFormId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platFormId);
  }

  loadGoogleAuth() {
    if (this.isBrowser) {
      this.stateService.startLoading();
      return new Promise((resolve, reject) => {
        const scriptElement = window.document.createElement('script');
        scriptElement.src = 'https://apis.google.com/js/platform.js';
        scriptElement.async;
        scriptElement.defer;
        scriptElement.onload = () => {
          this.stateService.endLoading();
          resolve('');
        };

        scriptElement.onerror = () => {
          this.stateService.endLoading();
          reject();
        };
        window.document.body.appendChild(scriptElement);
      });
    }
  }

  loadGoogleOneTap() {
    if (this.isBrowser) {
      this.stateService.startLoading();
      return new Promise((resolve, reject) => {
        const scriptElement = window.document.createElement('script');
        scriptElement.src = 'https://accounts.google.com/gsi/client';
        scriptElement.async;
        scriptElement.defer;
        scriptElement.onload = () => {
          this.stateService.endLoading();
          resolve('');
        };

        scriptElement.onerror = () => {
          this.stateService.endLoading();
          reject();
        };
        window.document.body.appendChild(scriptElement);
      });
    }
  }

  loadAppleScript() {
    if (this.isBrowser) {
      this.stateService.startLoading();
      return new Promise((resolve, reject) => {
        const scriptElement = window.document.createElement('script');
        scriptElement.src =
          'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        scriptElement.type = 'text/javascript';
        scriptElement.async;
        scriptElement.defer;
        scriptElement.onload = () => {
          this.stateService.endLoading();
          resolve('');
        };

        scriptElement.onerror = () => {
          this.stateService.endLoading();
          reject();
        };
        window.document.body.appendChild(scriptElement);
      });
    }
  }

  loadFreshwork() {
    if (this.isBrowser) {
      return new Promise((resolve, reject) => {
        const scriptContainer = window.document.createElement('div');
        const widget = window.document.createElement('script');
        widget.innerHTML = `
          window.fwSettings = {
            widget_id: 201000001383,
          };
          !(function () {
            if ('function' != typeof window.FreshworksWidget) {
              var n = function () {
                n.q.push(arguments);
              };
              (n.q = []), (window.FreshworksWidget = n);
            }
          })();
        `;
        widget.type = 'text/javascript';
        widget.async;
        widget.defer;
        scriptContainer.appendChild(widget);

        const script = window.document.createElement('script');
        script.src =
          'https://euc-widget.freshworks.com/widgets/201000001383.js';
        script.type = 'text/javascript';
        script.async;
        script.defer;
        scriptContainer.appendChild(script);

        script.onload = () => {
          resolve('');
        };
        script.onerror = () => {
          reject();
        };

        window.document.body.appendChild(scriptContainer);
      });
    }
  }
}
