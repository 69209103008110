import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls, userAccountUrls } from '../../shared/enum/routes';
import { LoginForm } from '../../shared/models/forms.model';
import { Login } from '../../shared/models/login.model';
import { UrlBuilderService } from '../../shared/services/url-builder.service';
@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  constructor(
    private http: HttpClient,
    private urlBuilder: UrlBuilderService
  ) {}

  userLogin(user: LoginForm): Observable<Login> {
    let url = this.urlBuilder.urlBuild(ApiUrls.loginUrl);
    return this.http.post<Login>(url, user);
  }

  smsLogin(phone): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.smsLogin);
    return this.http.post<any>(url, phone);
  }

  appleLogin(token): Observable<any> {
    let url = this.urlBuilder.urlBuild(userAccountUrls.firebaseLogin);
    return this.http.post<any>(url, token);
  }

  smsVerification(phone): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.smsVerification);
    return this.http.post<any>(url, phone);
  }

  googleLogin(googleToken): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.googleLogin);
    return this.http.post<any>(url, googleToken);
  }

  registerUser(user): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.signUp);
    return this.http.post<any>(url, user);
  }

  refreshToken(token): Observable<any> {
    let url = this.urlBuilder.userAccountUrlBuild(userAccountUrls.refreshToken, token);
    return this.http.post<any>(url, {});
  }

  phoneUpdateSendVerificationCode(phone) {
    let url = this.urlBuilder.urlBuild(ApiUrls.phoneUpdateSendVerificationCode);
    return this.http.post<any>(url, phone);
  }

  phoneUpdateVerifyCode(params) {
    let url = this.urlBuilder.urlBuild(ApiUrls.phoneUpdateVerifyCode);
    return this.http.post<any>(url, params);
  }

  firebaseLogin(token): Observable<any> {
    let url = this.urlBuilder.userAccountUrlBuild(userAccountUrls.firebaseLogin);
    return this.http.post<any>(url, token);
  }

  firebaseConnect(token): Observable<any> {
    let url = this.urlBuilder.userAccountUrlBuild(userAccountUrls.firebaseConnect);
    return this.http.post<any>(url, token);
  }

  firebaseValidateToken(token): Observable<any> {
    let url = this.urlBuilder.userAccountUrlBuild(userAccountUrls.firebasePhoneUpdate);
    return this.http.post<any>(url, token);
  }

  binanceLogin(token): Observable<any> {
    let url = this.urlBuilder.userAccountUrlBuild(userAccountUrls.binanceLogin);
    return this.http.post<any>(url, token);
  }
}
