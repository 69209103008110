import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RecentlySearchedService {
  private storageKey = 'recentlySearched';
  private maxItems = 5; // Maximum number of keywords to store

  private save(keywords: string[]) {
    localStorage.setItem(this.storageKey, JSON.stringify(keywords));
  }

  addKeyword(keyword: string): void {
    let keywords = this.getKeywords();

    // Remove the keyword if it already exists
    keywords = keywords.filter((k) => k !== keyword);

    // Add the keyword at the beginning of the array
    keywords.unshift(keyword);

    // Limit the number of items stored
    if (keywords.length > this.maxItems) {
      keywords.pop();
    }

    this.save(keywords);
  }

  getKeywords(): string[] {
    const storedKeywords = localStorage.getItem(this.storageKey);
    return storedKeywords ? JSON.parse(storedKeywords) : [];
  }

  removeKeyword(index: number) {
    const keywords = this.getKeywords();
    keywords.splice(index, 1);
    this.save(keywords);
    return keywords;
  }
}
