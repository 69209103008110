import { Component, OnInit } from '@angular/core';
import { StoreGroupResolverResolver } from '../../services/resolver/store-group-resolver.resolver';
import { StoreDetailService } from '../../services/resolver/store-detail.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {
  storeDetail:any
  storeGroups:any;
  storeGroupData:any
  constructor(
    private storeGroupResolver: StoreGroupResolverResolver,
    private storeDetailResolver:StoreDetailService,
  ) { }

  ngOnInit(): void {
    this.getStoreDetail();
    this.getStoreGroups();
  }

  getStoreDetail() {
    this.storeDetail = this.storeDetailResolver.permissionInit;
  }

  getStoreGroups() {
    this.storeGroups = this.storeGroupResolver.permissionInit?.stores_detail;
  }

}
