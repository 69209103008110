import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PanelService } from '../api/panel.service';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ExchangeResolverService {
  private exchangeDetail = new BehaviorSubject<any>('');
  platformId: Object;

  public constructor(
    private panelService: PanelService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId;
  }

  private resolveInfo(): Promise<any> {
    if(isPlatformServer(this.platformId)) {
      return Promise.resolve()
    }

    return new Promise((resolve, reject) => {
      this.panelService.getExchangeDetail().subscribe(
        data => {
          this.exchangeDetail.next(data);
          resolve("");
        }, error => {
          reject();
        }
      )
    })
  }

  get permissionInit() {
    return this.exchangeDetail.asObservable();
  }

  set permissionInit(info) {
    this.exchangeDetail.next(info);
  }

  public updateInfo() {
    this.resolveInfo();
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.resolveInfo();
  }
}
