import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideElementDirective {

  @Output() clickOutside = new EventEmitter<HTMLElement>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);    
    if (!clickedInside) {
      this.clickOutside.emit(targetElement);
    }
  }
}
