import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { PanelService } from '../api/panel.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { StateService } from '../storage/state.service';
import { Profile } from '../../models/profile.mode';

@Injectable({
  providedIn: 'root',
})
export class ProfileResolverService {
  private userProfile = new BehaviorSubject<Profile>(undefined);
  step: number;
  public constructor(
    private localStorageService: LocalStorageService,
    private panelService: PanelService,
    private stateService: StateService,
    private router: Router
  ) {}

  public resolveInfo(): Promise<any> {
    if (this.localStorageService.isLoggedIn) {
      return new Promise((resolve, reject) => {
        this.panelService.getUserProfile().subscribe(
          (data) => {
            this.userProfile.next(data);
            resolve('');
          },
          (error) => {
            reject();
          }
        );
      });
    }
  }

  get permissionInitInfo(): Observable<Profile> {
    return this.userProfile.asObservable();
  }

  set permissionInitInfo(info: any ) {
    this.userProfile.next(info);
  }

  public updateInfo() {
    return this.resolveInfo();
  }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    return this.resolveInfo();
  }
}
