import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StoreDetailService } from './resolver/store-detail.service';
import { isPlatformBrowser } from '@angular/common';
import { GoogleEventTypes } from '../enum/datalayer-event-types';
declare const dataLayer;

declare var gtag;
@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  gtmId: any;
  platformId: Object
  constructor(
    private storeDetailService: StoreDetailService,
    @Inject(PLATFORM_ID) platFormId: Object
  ) {
    this.platformId = platFormId;
    this.loadGoogleAnalytics();
  }
  loadGoogleAnalytics() {
    if(this.isClientPlatform(this.platformId)) {
      this.gtmId = this.storeDetailService?.permissionInit?.firebase_config?.gtmId
      return new Promise((resolve, reject) => {
        let scriptElement = window.document.createElement('script')
        scriptElement.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${this.gtmId}');`
        scriptElement.onload = () => {
          resolve("");
        };
        scriptElement.onerror = () => {
          reject();
        };
        window.document.head.appendChild(scriptElement);
        let noscriptElement = window.document.createElement('noscript');
        noscriptElement.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        noscriptElement.onload = () => {
          resolve("")
        };
        noscriptElement.onerror = () => {
          reject("")
        }
        window.document.body.appendChild(noscriptElement)
      })
    }
  }
  sendAnalytics(eventName: string, eventData: any) {
    this.resetDataLayerEventData();
    dataLayer.push({
      event: eventName,
      ...eventData
    });
  }

  private resetDataLayerEventData() {
    dataLayer.push(function() {
      this.reset();
    })
  }

  clearPreviousEcommerce(){
    dataLayer.push({ ecommerce: null })
  }
  
  setAnalyticItemCategories(list: any) {
    if (!list.length) return;
    let categories = {};
    list.forEach((el, i) => categories[`item_category${i + 1}`] = el.name);
    return categories;
  }

  isClientPlatform(platformId: Object) {
    if(isPlatformBrowser(platformId)) {
      return true
    } else return false
  }

  sendGoogleEvent(eventName: GoogleEventTypes, eventData: any) {
    this.resetDataLayerEventData();
    dataLayer.push({
      event: eventName,
      ecommerce: eventData
    });
  }
}
