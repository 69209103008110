import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiUrls } from '../enum/routes';

@Injectable({
  providedIn: 'root',
})
export class UrlBuilderService {
  private baseUrl: string = environment.baseUrl;
  private firebaseUrl: string = environment.userAccountBaseUrl;

  constructor() { }

  urlBuild(...uris): string {
    return this.joinUrlParts(this.baseUrl, uris);
  }

  userAccountUrlBuild(...uris): string {
    return this.joinUrlParts(this.firebaseUrl, uris);
  }

  private joinUrlParts(baseUrl: string, uris: string[]): string {
    return `${baseUrl}/${uris.join('/')}/`;
  }
}
