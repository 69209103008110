import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { EMPTY, Observable } from 'rxjs';
import { Mobile_breakpoint } from './breakpoints';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectorService {
  isDesktop: boolean;
  private isDesktopBreakpoint: Observable<BreakpointState> =
    this.breakpointObserver.observe([`(min-width: ${Mobile_breakpoint}px)`]);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private breakpointObserver: BreakpointObserver
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isDesktopBreakpoint.subscribe((size) => {
        this.isDesktop = size.matches;
      });
      // this.isDesktop = this.deviceService.isDesktop()
    } else {
      this.isDesktop = false; // Default for server-side rendering
    }
  }

  public $breakpoint(): Observable<BreakpointState> {
    return isPlatformBrowser(this.platformId)
      ? this.isDesktopBreakpoint
      : EMPTY;
  }
}
