<div class="navigation-wrapper" *ngIf="!hideNavigation">
    <div class="mobile-nav">
        <div class="nav-item">
            <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <div class="bg-container">
                    <img class="icons unselected" src="/assets/images/icons/navigation-home.svg" alt="" />
                    <img class="icons icon-selected" src="/assets/images/icons/navigation-home-fill.svg" alt="" />
                    <p>{{'nav.home' | translate}}</p>
                </div>
            </a>
        </div>
        <div class="nav-item">
            <a [routerLink]="['/category']" routerLinkActive="active">
                <div class="bg-container">
                    <img class="icons unselected" src="/assets/images/icons/navigation-category.svg" alt="" />
                    <img class="icons icon-selected" src="/assets/images/icons/navigation-category-fill.svg" alt="" />
                    <p>{{'nav.categories' | translate}}</p>
                </div>
            </a>
        </div>
        <div class="nav-item">
            <!-- <a [routerLink]="['/profile']" [queryParams]="{tab: 'shopSummary'}" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="bg-container">
                    <img class="icons unselected" src="/assets/images/icons/navigation-notepad.svg" alt="" />
                    <img class="icons icon-selected" src="/assets/images/icons/navigation-notepad-fill.svg" alt="" />
                    <p>{{'nav.orders' | translate}}</p>
                </div>
            </a> -->
            <a [routerLink]="['/order-history']" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <div class="bg-container">
                <img class="icons unselected" src="/assets/images/icons/navigation-notepad.svg" alt="" />
                <img class="icons icon-selected" src="/assets/images/icons/navigation-notepad-fill.svg" alt="" />
                <p>{{'nav.orders' | translate}}</p>
            </div>
        </a>
        </div>
        <div class="nav-item">
            <a [routerLink]="['/profile']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <div class="bg-container">
                    <img class="icons unselected" src="/assets/images/icons/navigation-user.svg" alt="" />
                    <img class="icons icon-selected" src="/assets/images/icons/navigation-user-fill.svg" alt="" />
                    <p>{{'nav.profile' | translate}}</p>
                </div>
            </a>
        </div>
    </div>
</div>