import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    private titleService: Title
  ) { }

  public updateTitle(title: string) {
    this.titleService.setTitle(title);
  }
}
