<div class="search-form" [ngClass]="{opened}" #searchFielfContainer (clickOutside)="handleClickOutside($event)">
    <div class="form-container">
      <div class="input-main">
        <button class="transparent" type="button" (click)="backToPrevRoute()">
          <img src="/assets/images/icons/left-arrow-icon.svg" alt="" />
        </button>
        <div class="input-field">
          <input id="search-field-input" matInput autocomplete="off" type="text" placeholder="Search..." [formControl]="searchField" (click)="onFocusSearchField()" (keyup.enter)="goToDetails()"/>
          <mat-spinner *ngIf="state === searchState.SEARCHING" diameter="15"></mat-spinner>
        </div>
        <button *ngIf="searchField.value" class="transparent clear-btn" type="button" (click)="clearSearch()">
          <img src="/assets/images/icons/close-icon.svg" alt="">
        </button>
      </div>
      <button class="primary" type="button" (click)="goToDetails()">
        <img src="/assets/images/icons/search_icon.svg" alt="" />
      </button>
    </div>    
</div>

<ng-template #searchDialog>
  <ng-container *ngIf="!searchResult">
    <search-suggestion-view></search-suggestion-view>
  </ng-container>
  <ng-container *ngIf="searchResult">
    <search-brief-result [searchResult]="searchResult" [query]="searchedQuery"></search-brief-result>
  </ng-container>
</ng-template>
