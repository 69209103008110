import { isPlatformServer } from '@angular/common';
import { Injectable } from '@angular/core';
import { Attributes, IntersectionObserverHooks } from 'ng-lazyload-image';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadService extends IntersectionObserverHooks {

  setup(attributes: Attributes) {
    if(!attributes.imagePath) {
      attributes.imagePath = attributes.defaultImagePath
    }
    
    super.setup(attributes);
  }

  isDisabled() {
    // Disable the default behaviour
    return false;
  }

  skipLazyLoading() {
    return isPlatformServer(this.platformId); 
  }
}
