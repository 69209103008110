import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import {
  BinanceAction,
  BinanceBridgeKey,
  BinancePaymentPayload,
} from './binance.model';
import { isPlatformBrowser } from '@angular/common';
import { AuthenticateService } from 'src/app/modules/authenticate/services/authenticate.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { ProfileResolverService } from '../resolver/profile-resolver.service';
import { StateService } from '../storage/state.service';
import { Router } from '@angular/router';

declare var bn; // Global Binance Entry

@Injectable({
  providedIn: 'root',
})
export class BinanceService {
  #isBinance: boolean;

  constructor(
    private authenticateService: AuthenticateService,
    private localStorage: LocalStorageService,
    private profileResolver: ProfileResolverService,
    private stateService: StateService,
    private zone: NgZone,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.#isBinance = isPlatformBrowser(platformId) && window[BinanceBridgeKey];
  }

  get isBinance() {
    return this.#isBinance;
  }

  private binanceLogin(token: any, redirectUrl?: string) {
    this.authenticateService.binanceLogin({ token }).subscribe((res) => {
      this.localStorage.setItem('accessToken', res.token);
      this.localStorage.setItem('refreshToken', res.refresh_token);
      this.profileResolver.updateInfo();
      this.stateService.setLoginStatus(true);
      if (redirectUrl) {
        this.zone.run(() => {
          this.router.navigateByUrl(redirectUrl);
        });
      }
    });
  }

  autoLoginBinance(redirectUrl?: string) {
    if (!this.isBinance) return;

    bn.onMessage = ({ data }) => {
      if (data['action'] === BinanceAction.GET_USER_OPEN_ID) {
        let token = data['payload']['token'];
        if (token) {
          this.binanceLogin(token, redirectUrl);
        }
        return;
      }
    };

    bn.miniProgram.postMessage({
      action: BinanceAction.GET_USER_OPEN_ID,
      payload: { description: 'check user' },
    });
  }

  requestBinancePayment(payload: BinancePaymentPayload, redirectUrl?: string) {
    if (!this.isBinance) return;

    bn.onMessage = ({ data }) => {
      if (data['action'] === BinanceAction.REQUEST_PAYMENT) {
        this.router.navigateByUrl(redirectUrl);
      }
    };
    bn.miniProgram.postMessage({
      action: BinanceAction.REQUEST_PAYMENT,
      payload,
    });
  }
}
