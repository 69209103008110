import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessDeniedComponent } from './modules/category/components/access-denied/access-denied.component';
import { WrapperComponent } from './modules/shared/components/wrapper/wrapper.component';
import { ExchangeResolverService } from './modules/shared/services/resolver/exchange-resolver.service';
import { ProfileResolverService } from './modules/shared/services/resolver/profile-resolver.service';
import { StoreDetailService } from './modules/shared/services/resolver/store-detail.service';
import { WalletBalanceService } from './modules/shared/services/resolver/wallet-balance.service';
import { AccessDeniedGuard } from './modules/shared/services/guard/access-denied.guard';
import { StoreGroupResolverResolver } from './modules/shared/services/resolver/store-group-resolver.resolver';
import { StoreRegionService } from './modules/shared/services/store-region.service';

const routes: Routes = [
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    canActivate: [AccessDeniedGuard]
  },
  {
    path: '',
    component: WrapperComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./modules/category/category.module').then(
                    (m) => m.CategoryModule
                  ),
              },
              {
                path: '',
                loadChildren: () =>
                  import('./modules/product/product.module').then(
                    (m) => m.ProductModule
                  ),
                resolve: [WalletBalanceService]
              },
            ],
          },
          {
            path: 'authenticate',
            loadChildren: () =>
              import('./modules/authenticate/authenticate.module').then(
                (m) => m.AuthenticateModule
              ),
          },
        ],
        resolve: [ExchangeResolverService, ProfileResolverService,StoreGroupResolverResolver]
      }
    ],
    resolve: [StoreDetailService, StoreRegionService],
  },

  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
