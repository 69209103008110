<div class="section-container">
  <div
      class="chip-container"
      *ngIf="recentSearches && recentSearches.length > 0"
    >
      <div class="chip-header">
        <div class="icon-co">
          <img src="/assets/images/icons/clock-icon.svg" alt="" />
        </div>
        <h4 class="chip-title">Recent Searches</h4>
      </div>
      <div class="chip-main">
        <mat-chip-list aria-label="Recent Searches">
          <mat-chip
            *ngFor="let item of recentSearches"
            (removed)="removeRecentlySearchedItem(item)"
            (click)="goToQuery(item)"
            class="outline-chip"
          >
            {{ item }}
            <button matChipRemove class="remove-icon">
              <div class="icon-co">
                <img src="/assets/images/icons/close-icon.svg" alt="" />
              </div>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
  </div>
  
  <div
      class="chip-container"
      *ngIf="trendingSearches && trendingSearches.length > 0"
  >
      <div class="chip-header">
        <div class="icon-co">
          <img src="/assets/images/icons/clock-icon.svg" alt="" />
        </div>
        <h4 class="chip-title">Trending Searches</h4>
      </div>
      <div
        class="chip-main"
        *ngIf="trendingSearches && trendingSearches.length > 0"
      >
        <mat-chip-list aria-label="Trending Searches">
          <mat-chip
            *ngFor="let item of trendingSearches"
            class="outline-chip"
            (click)="goToQuery(item)"
          >
            {{ item }}
          </mat-chip>
        </mat-chip-list>
      </div>
  </div>
</div>