<div class="result-container" *ngIf="searchResult?.length > 0">
    <div class="result-main">
      <a
        class="search-option"
        *ngFor="let item of searchResult | slice : 0 : resultLimit"
        [routerLink]="[item.url]"
        (click)="onNavigate(item)"
      >
        <img
          [lazyLoad]="item.logo"
          [alt]="item.name"
          defaultImage="assets/images/cards/ic_placeholder.png"
          class="result-image"
        />
        <p class="result-context">{{ item.name }}</p>
        <div class="nav-icon">
          <img src="/assets/images/icons/arrow-up-right-icon.svg" alt="" />
        </div>
      </a>
    </div>
    <div class="button-container" *ngIf="searchResult?.length > resultLimit">
      <button
        class="stroked-button"
        mat-stroked-button
        color="primary"
        (click)="closeModals()"
        [routerLink]="['/search', query , 'all']"
      >
        All Result
      </button>
    </div>
</div>

<p *ngIf="searchResult?.length === 0" class="no-result-context">
  Unfortunately, no results found!
</p>