import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CHANNEL_SOURCE, ChannelSourceHeader } from './channel-source.model';
import { Mobile_breakpoint } from '../device-detector/breakpoints';
import { userAccountUrls } from '../../enum/routes';
import { BinanceService } from '../binance/binance.service';

// const SourceSensetiveRoutes = [
//   ApiUrls.gateway,
//   ApiUrls.orders,
//   ApiUrls.payments,
//   ApiUrls.products,
//   ApiUrls.home,
//   ApiUrls.homes,
//   ApiUrls.storeCountries,
// ];

@Injectable({
  providedIn: 'root',
})
export class ChannelSourceService {
  private source: CHANNEL_SOURCE;

  constructor(
    private binanceService: BinanceService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    const isServer = isPlatformServer(platformId);

    if (isServer) {
      this.source = CHANNEL_SOURCE.DESKTOP;
      return;
    }

    if (this.binanceService.isBinance) {
      this.source = CHANNEL_SOURCE.BINANCE;
      return;
    }

    const isMobile = window.innerWidth < Mobile_breakpoint;
    this.source = isMobile ? CHANNEL_SOURCE.MOBILE : CHANNEL_SOURCE.DESKTOP;
  }

  private isUserAccountUrl(route: string) {
    return Object.values(userAccountUrls).some((url) => route.includes(url));
  }

  channelSourceHeader(routeUrl: string): ChannelSourceHeader {
    return {
      ...(!this.isUserAccountUrl(routeUrl) && {
        'X-CHANNEL-SOURCE': this.source,
      }),
    };
  }
}
