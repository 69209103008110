import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { ApiUrls } from '../../enum/routes';

interface CacheOptions {
  urlsToCache: string[];
  ttls?: { [url: string]: number };
  globalTTL?: number;
}

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  private requests = new Map<
    string,
    {
      src: string;
      data: HttpResponse<any>;
      data$: Subject<HttpResponse<any>>;
      params?: any;
      ttl?: number;
    }
  >();

  private options?: CacheOptions = {
    urlsToCache: [
      ApiUrls.homes,
      ApiUrls.home,
      ApiUrls.category,
      ApiUrls.exchangeDetail,
      // ApiUrls.FAQ,
      // ApiUrls.products,
    ],
    globalTTL: 180000, // 3 min
  };

  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { urlsToCache = [] } = this.options ?? {};
    const key = this.getUniqueKey(req);
    const needCache = urlsToCache.some((x) => new RegExp(x).test(req.url));

    const prevRequest = this.requests.get(key);

    if (needCache) {
      if (prevRequest) {
        const { data, data$, ttl } = prevRequest;

        if (!data$.closed) {
          return data$;
        }

        if (data && ttl && ttl > new Date().getTime()) {
          return of(data);
        }

        prevRequest.data$ = new Subject<any>();
      } else {
        this.requests.set(key, {
          src: req.url,
          data$: new Subject<HttpResponse<any>>(),
          data: new HttpResponse<any>(),
          params: req.body,
          ttl: this.getTTL(req.url),
        });
      }
    }

    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((event) => {
        const data = event as HttpResponse<any>;
        const currentRequest = this.requests.get(key);
        if (!currentRequest) return;

        currentRequest.data = data;
        currentRequest.ttl = this.getTTL(req.url);
        !currentRequest.data$.closed && currentRequest.data$.next(data);
      }),
      finalize(() => {
        const currentRequest = this.requests.get(key);
        currentRequest?.data$.complete();
        currentRequest?.data$.unsubscribe();
      })
    );
  }

  private getUniqueKey(req: HttpRequest<unknown>): string {
    const bodySorted = this.sortObjectByKeys(req.body);
    const key = `${req.method}_${req.url}_${req.params.toString()}_${JSON.stringify(bodySorted)}`;
    return key;
  }

  private sortObjectByKeys(obj: any): any {
    const keysSorted = Object.keys(obj ?? '').sort();
    const objSorted = keysSorted.reduce((_obj, key) => {
      const val = obj[key];
      _obj[key] = typeof val === 'object' ? this.sortObjectByKeys(val) : val;
      return _obj;
    }, {} as any);

    return objSorted;
  }

  private getTTL(reqUrl: string): number {
    const { ttls, globalTTL } = this.options ?? {};

    const getCustomTTL = () => {
      const matchedKey = Object.keys(ttls ?? '').find((x) =>
        reqUrl.split('?')[0].endsWith(x)
      );

      return matchedKey ? ttls[matchedKey] : null;
    };

    return new Date().getTime() + (getCustomTTL() || globalTTL || 0);
  }
}
