<div class="prefrences-container">
    <div class="dialog-header">
      <p class="title">Preferences:</p>
    </div>
    <div class="dialog-content">
      <div class="note-container">
        <div class="note-header">
            <img src="/assets/images/icons/info-icon.svg" alt="">
            <p class="note-title">Attention:</p>
        </div>
        <p class="note-text">
          By changing your region, the website will show different products, specific for that region.
        </p>
      </div>
      <div class="form-container">

        <div class="form-item">
          <mat-label class="form-label">Region</mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              *ngIf="filteredCountryList"
              [formControl]="storeRegionSelect"
              class="region-select"
              [panelClass]="'custom-select-panel'"
              (openedChange)="clearSearchField()"
            >
              <mat-select-trigger class="region-row">
                <img [src]="storeRegionSelect?.value?.image" alt="" />
                <p>{{storeRegionSelect?.value?.name}}</p>
              </mat-select-trigger>
                <mat-form-field class="mat-select-search">
                  <input
                    matInput
                    type="text"
                    placeholder="Search countries..."
                    [(ngModel)]="searchedCountry"
                    (ngModelChange)="filterCountries()"
                    
                    autocomplete="off"
                  />
                </mat-form-field>
              <mat-option
                *ngFor="let country of filteredCountryList"
                class="region-row"
                [ngClass]="{'first-opt': filteredCountryList.length === countryList.length}"
                [value]="country"
              >
                <img [src]="country.image" alt="" />
                <p>{{country.name}}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="form-item">
          <mat-label class="form-label">Language</mat-label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="languageControl" disabled>
              <mat-option *ngFor="let lang of availableLanguages" class="region-row" [value]="lang">
                <p class="select-option-name">{{ lang.str }}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>
    <div class="dialog-footer">
      <button mat-button (click)="discardPrefrences()">Cancel</button>
      <button mat-button class="save-button" (click)="savePrefrences()">Save Preferences</button>
    </div>
  </div>