import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/storage/state.service';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss']
})
export class MobileNavigationComponent implements OnInit {
  isLoggedIn: boolean = false;
  hideNavigation: boolean = false;
  constructor(
    private stateService: StateService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.hideNavigation = this.isNoneNavigationRoute;
    this.setLoginStatus();
    
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.hideNavigation = this.isNoneNavigationRoute;
      }
    })
  }

  private get isNoneNavigationRoute() {
    return this.router.url.includes('/authenticate') || this.router.url.includes('/profile/edit') || this.router.url.includes('/checkout') || this.router.url.includes('/wallet/charge') || this.router.url.includes('/payment');
  }

  setLoginStatus() {
    this.stateService.setLoginStatus(this.localStorageService.isLoggedIn);
    let subscription = this.stateService
      .getLoginStatus()
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
  }
}
