import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { PreferenceComponent } from '../../components/preference/preference.component';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {
  private dialogRef: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog
  ) { }

  openPreference() {
    if(this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) return;
   
      this.dialogRef = this.dialog.open(PreferenceComponent, {
        panelClass: 'preference-dialog'
      });
   }

   closePreference() {
    this.dialogRef.close();
   }
}
