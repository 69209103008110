import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SnackBarService } from '../snack-bar.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { StateService } from '../storage/state.service';
import { AuthenticateService } from 'src/app/modules/authenticate/services/authenticate.service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ApiUrls } from '../../enum/routes';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements HttpInterceptor {
  refreshTokenInProgress = false;
  platformId: any;
  constructor(
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
    private authenticateService: AuthenticateService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error?.code === 4041 || error.error?.code === 4177) {
          if (!this.localStorageService.isLoggedIn) {
            this.localStorageService.logOutUser();
            return;
          }
          if (
            !this.refreshTokenInProgress &&
            this.localStorageService.getItem('refreshToken')
          ) {
            this.refreshTokenInProgress = true;
            let refresh = this.localStorageService.getItem('refreshToken');
            return this.authenticateService.refreshToken(refresh).pipe(
              switchMap((res) => {
                this.localStorageService.setItem(
                  'refreshToken',
                  res.refresh_token
                );
                this.localStorageService.setItem('accessToken', res.token);
                this.refreshTokenInProgress = false;
                req = req.clone({
                  setHeaders: {
                    Token: `${res.token}`,
                  },
                });
                return next.handle(req);
              }),
              catchError((e) => {
                this.localStorageService.logOutUser();
                window.location.reload();
                return next.handle(req);
              })
            );
          } else {
            this.localStorageService.logOutUser();
          }
        }

        if (
          !this.localStorageService.isLoggedIn &&
          error.status === 401 &&
          error.error?.code !== 4041
        ) {
          this.localStorageService.logOutUser();
        }

        if (isPlatformBrowser(this.platformId)) {
          if (error.error instanceof HttpErrorResponse) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = ` Error: ${error.error?.detail}`;
          }
        }
        if (
          !req.url.includes(ApiUrls.FAQ) &&
          !req.url.includes(ApiUrls.logProductSearch)
        ) {
          this.snackBarService.showSnackBar(errorMsg, 'primary', 2000);
        }
        //   if(error.error?.code == '4162' || error.error?.code == '4161'){
        //     this.router.navigate(['/profile'], {
        //       queryParams: {
        //         tab: 'kyc-form',
        //       },
        //     });
        // }
        if (error.error?.code == '4132') {
          this.router.navigate(['/profile'], {
            queryParams: {
              tab: 'billing-info',
            },
          });
        }
        if (error.error?.code == '4155') {
          this.router.navigate(['/profile'], {
            queryParams: {
              tab: 'edit',
            },
          });
        }
        return throwError(error);
      })
    );
  }
}
