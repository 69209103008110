<div class="dialog-container">
  <div class="header-title flex">
    <div class="flex title">
      <img src="assets/images/icons/cookie.svg" alt="">
      <p>Your Privacy is Important</p>
    </div>
    <img src="assets/images/icons/close-icon.svg" alt="" class="pointer" (click)="closeDialog()">
  </div>
  <!-- <mat-label>{{'nav.region' | translate}}:</mat-label> -->    
  <div class="dialog-main">
    <p class="dialog-main-text cookie-desc">
      {{'cookie.modalDesc' | translate}}
      <a [routerLink]="[ '/pages/cookie-policy']">Cookie Notice</a>
      &      
      <a [routerLink]="[ '/pages/privacy-policy']">Privacy Statement</a>
    </p>
  </div>
  <div class="cookie-button-container flex">
    <p class="pointer flat-button" (click)="openSettings()">Cookie Settings</p>
    <button class="accept-button button outline-button" (click)="acceptAll()">Accept All</button>
  </div>
</div>

<ng-template #customSettings>
  <div class="settings-dialog-container">
    <div class="cookie-settings-header w-full">
      <h2>Cookie Settings</h2>
    </div>
    <hr class="custom-hr">
    <div class="settings-detail w-full flex">
      <div class="settings-info flex">
        <p class="settings-message">We Use Cookies that Ensure a Reliable, Personalized and Safe Shopping Experience.</p>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Strictly&nbsp;Necessary&nbsp;Cookies
            </mat-panel-title>
            <mat-panel-description>
              <span class="spacer"></span>
              <mat-slide-toggle
              checked="true"
              disabled="true"
              ></mat-slide-toggle>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="settings-description">These cookies are necessary for the website to function and cannot be switched off</p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Analytics&nbsp;cookies
            </mat-panel-title>
            <mat-panel-description>
              <span class="spacer"></span>
              <mat-slide-toggle
              (click)="$event.stopPropagation()"
              (change)="toggleCookie()"
              [checked]="analyticsCookie"
              ></mat-slide-toggle>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="settings-description">These cookies collect information that is used either in aggregate form to help us understand how our websites are being used or how effective our marketing campaigns are.</p>
        </mat-expansion-panel>
      </mat-accordion>
      </div>
      <div class="settings-actions flex w-full">
        <p class="cancel-button pointer" (click)="closeAllDialogs()">Cancel</p>
        <button class="button primary-button w-full" (click)="confirmCustom()">
          Confirm My Choices
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="dialog-container dialog-large">
    <p class="title">Manage Consent Preferences</p>
    <div class="dialog-main">
      <div class="dialog-desc">
        <p class="dialog-main-text">
          We use cookies that ensure a reliable, personalized and safe shopping experience.
        </p>
      </div>
      <div class="expand-container">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Strictly&nbsp;Necessary&nbsp;Cookies
              </mat-panel-title>
              <mat-panel-description>
                <span class="spacer"></span>
                <mat-slide-toggle
                checked="true"
                disabled="true"
                ></mat-slide-toggle>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>These cookies are necessary for the website to function and cannot be switched off</p>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Analytics&nbsp;cookies
              </mat-panel-title>
              <mat-panel-description>
                <span class="spacer"></span>
                <mat-slide-toggle
                (click)="$event.stopPropagation()"
                (change)="toggleCookie()"
                [checked]="analyticsCookie"
                ></mat-slide-toggle>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>These cookies collect information that is used either in aggregate form to help us understand how our websites are being used or how effective our marketing campaigns are.</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="button-container">
      <button mat-raised-button color="primary" (click)="confirmCustom()">Confirm My Choices</button>
    </div>
  </div> -->
</ng-template>

