import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CountryDetail } from '../../models/profile.mode';
import { FormControl } from '@angular/forms';
import { StoreRegionService } from '../../services/store-region.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { StateService } from '../../services/storage/state.service';
import { environment } from 'src/environments/environment';
import { Language } from '../../enum/Language';
import { TranslateService } from '@ngx-translate/core';
import { PreferenceService } from '../../services/preference/preference.service';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss'],
})
export class PreferenceComponent implements OnInit {
  filteredCountryList: CountryDetail[];
  selectedRegion: CountryDetail;
  storeRegionSelect: FormControl<CountryDetail> = new FormControl();
  languageControl: FormControl = new FormControl();
  searchedCountry = '';
  private countryList: CountryDetail[];
  private isBrowser: boolean;
  selectedLang: String = 'En';
  languageImage: any = '';
  languageCode: any = '';
  availableLanguages = [
    {
      code: 'en',
      str: 'English',
      img: 'assets/images/icons/usa-flag.svg',
      primaryImage: 'assets/images/icons/usa-primary-flag.png',
    },
    {
      code: 'ar',
      str: 'العربية',
      img: 'assets/images/icons/uae-flag.svg',
      primaryImage: 'assets/images/icons/uae-primary-flag.png',
    },
    {
      code: 'tr',
      str: 'Türkçe',
      img: 'assets/images/icons/turkey-flag.svg',
      primaryImage: 'assets/images/icons/turkey.png',
    },
  ];

  constructor(
    private storeRegionService: StoreRegionService,
    private googleTagManagerService: GoogleTagManagerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private stateService: StateService,
    private translateService: TranslateService,
    private preferenceService: PreferenceService,
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.checkUserLanguage();
    this.initRegion();
  }

  initRegion() {
    this.filteredCountryList = this.countryList =
      this.storeRegionService.countryList;
    this.selectedRegion = this.storeRegionService.selectedRegion;
    if (!this.selectedRegion) {
      return;
    }
    this.storeRegionSelect.patchValue(
      this.filteredCountryList.find(
        (item) => item.id === this.selectedRegion.id
      ),
      { emitEvent: false }
    );
  }

  filterCountries() {
    const searchTextLower = this.searchedCountry.toLowerCase();
    this.filteredCountryList = this.countryList.filter((country) =>
      country.name.toLowerCase().includes(searchTextLower)
    );
  }

  clearSearchField() {
    this.searchedCountry = '';
    this.filteredCountryList = this.countryList;
  }

  discardPrefrences() {
    this.preferenceService.closePreference();
  }

  savePrefrences() {
    this.changeLanguage(this.languageControl.value.code);
    if (this.selectedRegion?.id != this.storeRegionSelect.value.id) {
      this.googleTagManagerService.sendAnalytics('change_region', {
        from: this.selectedRegion.code,
        to: this.storeRegionSelect.value.code,
      });
      this.storeRegionService.setRegion(this.storeRegionSelect.value);
      if (this.router.url.includes('category')) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { country: this.storeRegionSelect.value.id },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      } else {
        window.location.reload();
      }
      this.selectedRegion = this.storeRegionSelect.value;
    }
    this.preferenceService.closePreference();
  }

  checkUserLanguage() {
    let userLang;
    if (this.isBrowser) {
      userLang = this.localStorageService.getItem('userLang');
    }

    if (userLang) {
      this.changeLanguage(userLang, true);
    } else {
      this.changeLanguage(environment.defaultLang, true);
    }
  }

  changeLanguage(lang: any, isInitial?: boolean) {
    if (!isInitial) {
      this.googleTagManagerService.sendAnalytics('change_language', {
        from: this.stateService.userLanguage.value,
        to: lang,
      });
    }
    let languageObject = this.availableLanguages.find(
      (obj) => obj?.code === lang
    );
    this.languageControl.patchValue(languageObject);
    this.languageImage = languageObject.primaryImage;
    this.languageCode = languageObject.str;
    if (this.isBrowser) {
      this.localStorageService.setItem('userLang', lang);
    }
    this.stateService.setLanguageStatus(lang);
    this.selectedLang = Language[lang];
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    if (lang === 'fa' || lang == 'ar') {
      htmlTag.dir = 'rtl';
    } else {
      htmlTag.dir = 'ltr';
    }
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }
}
